<template>
  <div
    id="search-airport"
    ref="SearchAirport"
  >
    <!-- ANCHOR input -->
    <div
      class="position-relative bg-white rounded px-50 py-25"
      style="height: 60px"
    >
      <label
        :for="`search-airport-input-${idSearch}`"
        class="d-block text-truncate w-100 h-100 mb-0"
        @click="isShowAirportGroup = !isShowAirportGroup"
      >
        <div class="d-flex justify-content-start text-title">
          <div
            class="text-dark mb-0"
            :class="isMultiCity ? ' font-medium-3' : ' font-medium-4'"
          >
            {{ airportData?.city || airportData?.name }}
          </div>
          <span class="text-primary ml-50">{{ airportData?.iata }}</span>
        </div>
        <div class="d-flex justify-content-start align-items-center">
          <div class="d-flex justify-content-between">
            <div
              class="text-black-50 mb-0"
              style="font-weight: 400"
            >
              {{ airportData?.city ? `${airportData?.name},` : '' }} {{ airportData?.country || airportData?.group }}
            </div>
          </div>
        </div>
      </label>
      <b-form-input
        :id="`search-airport-input-${idSearch}`"
        :ref="SearchAirportInput"
        v-model="searchText"
        class="position-absolute position-top-0 position-left-0 d-none w-100 bg-white border-0 font-medium-3 fw-600"
        :class="{ 'd-block': isShowAirportGroup }"
        :formatter="value => value.toUpperCase()"
        style="height: 60px; color: #0e6c8e"
        autocomplete="off"
        :placeholder="`${ airportData?.city || airportData?.name ? `${airportData?.city || airportData?.name},` : '' } ${airportData?.iata ? `(${airportData?.iata})` : ''}`"
        @input="handleSearchAirport"
        @keyup.enter="onEnterInput"
      />
    </div>
    <!-- ANCHOR select -->
    <div
      v-if="isShowAirportGroup"
      class="position-absolute bg-white  rounded box-shadow-1"
      style="left: 0; top: 105%; z-index: 99; width: 100%; max-height: 380px; padding: 4px 0;"
    >
      <div
        v-if="!searchText"
        class="d-flex"
        style="max-height: 372px;"
      >
        <!-- GROUP -->
        <div>
          <div
            v-for="(airportGroup, index) in getAirportGroup.groups"
            :key="index"
            class="py-50 cursor-pointer px-1 hover"
            :class="{ 'bg-primary text-white': index === groupSelectedIndex }"
            @click="handleChooseGroup(index)"
          >
            <span class="text-uppercase">{{ airportGroup.displayName }}</span>
          </div>
        </div>
        <!-- AIRPORT ITEM -->
        <div class="overflow-auto flex-1">
          <div
            v-for="(airport, index) in airportOfSelectedGroup"
            :key="index"
            class="py-25 px-1 cursor-pointer hover overflow-auto"
            :class="{ 'bg-light-primary': airport.iata === airportData?.iata }"
            @click="onSelectAirport(airport)"
          >
            {{ airport.name }} ({{ airport.iata }})
          </div>
        </div>
      </div>
      <div
        v-else
        style="max-height: 380px;"
        class="overflow-auto p-0"
      >
        <div
          v-if="isLoadingSearchAirport"
          class="d-flex-center"
        >
          <b-spinner
            variant="primary"
            label="Text Centered"
            small
            tag="span"
          />
          <span class="pl-1"> {{ $t('flight.loadingAirport') }} </span>
        </div>
        <div
          v-else-if="!airportOptions.length"
          class="d-flex-center"
        >
          {{ $t('flight.noOptions') }}
        </div>
        <div v-else>
          <div
            v-for="(airport, index) in airportOptions"
            :key="index"
            class="py-25 px-1 cursor-pointer hover"
            :class="{ 'bg-light-primary text-white': airport.iata === airportData?.iata }"
            @click="onSelectAirport(airport)"
          >
            <div class="d-flex justify-content-start text-truncate">
              <strong>{{ airport?.city }}</strong>
              <strong :class="`text-primary ml-1 text-truncate font-weight-bold`">
                {{ airport?.iata }}
              </strong>
            </div>
            <div class="d-flex justify-content-between text-truncate">
              <span class="text-truncate">
                {{ airport?.name }}, {{ airport?.country }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed, ref, toRefs, watchEffect,
} from '@vue/composition-api'
import { BFormInput, BSpinner } from 'bootstrap-vue'
import { onClickOutside } from '@vueuse/core'
import { v4 as uuidv4 } from 'uuid'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'

import { removeAccents } from '@core/comp-functions/forms/formatter-input'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: { BFormInput, BSpinner },
  props: {
    dataProp: {
      type: [Object, String],
      default: () => {},
    },
    typeProp: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    right: {
      type: Boolean,
      default: false,
    },
    isMultiCity: {
      type: Boolean,
      default: false,
    },
    isSearchClassBooking: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const {
      getAirportGroup, fetchAirports, getAirportByAirportCode, getAirportsByCodeList,
    } = useBookingHandle()
    const isShowAirportGroup = ref(false)
    const groupSelectedIndex = ref(0)

    function handleChooseGroup(index) {
      groupSelectedIndex.value = index
    }

    const airportOfSelectedGroup = computed(() => getAirportGroup.value.groups[groupSelectedIndex.value].airports)
    const searchText = ref(null)

    function onSelectAirport(airport) {
      emit('update:data-prop', airport)
      isShowAirportGroup.value = false
      searchText.value = null
    }
    const SearchAirportInput = ref()
    const SearchAirport = ref()

    onClickOutside(SearchAirport, () => {
      isShowAirportGroup.value = false
      searchText.value = null
    })

    const idSearch = uuidv4()

    const airportOptions = ref([])
    const isLoadingSearchAirport = ref(false)

    function fetchAirportHandle(searchTextAirport = null) {
      airportOptions.value = []
      isLoadingSearchAirport.value = true

      fetchAirports(searchTextAirport)
        .then(response => {
          if (response) {
            airportOptions.value = response.data.items
          }
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          isLoadingSearchAirport.value = false
        })
    }

    const handleSearchAirport = debounce(searchTextAirport => {
      if (searchTextAirport) fetchAirportHandle(removeAccents(searchTextAirport))
    }, 500)

    const airportProp = toRefs(props).dataProp
    const fetchingAirport = ref(false)
    const airportData = ref(null)

    watchEffect(async () => {
      airportData.value = null
      if (typeof airportProp.value === 'string' && !isEmpty(airportProp.value)) {
        const temp = getAirportByAirportCode(airportProp.value)
        if (temp) {
          airportData.value = temp
        } else if (!fetchingAirport.value) {
          fetchingAirport.value = true
          await getAirportsByCodeList([airportProp.value])
            .then(response => {
              airportData.value = response.data.find(point => point.iata === airportProp.value) || null
            })
            .finally(() => {
              fetchingAirport.value = false
            })
        }
      } else {
        airportData.value = cloneDeep(airportProp.value)
      }
    })
    function onEnterInput() {
      const findAirport = airportOptions.value.find(i => i?.iata === searchText.value)
      if (findAirport) {
        emit('update:data-prop', findAirport)
        isShowAirportGroup.value = false
        searchText.value = null
      }
    }

    return {
      isShowAirportGroup,
      getAirportGroup,
      airportOfSelectedGroup,
      handleChooseGroup,
      groupSelectedIndex,
      onSelectAirport,
      SearchAirportInput,
      SearchAirport,
      idSearch,
      handleSearchAirport,
      airportOptions,
      searchText,
      isLoadingSearchAirport,
      airportData,
      onEnterInput,
    }
  },
}
</script>

<style lang="scss" scoped>
#search-airport ::v-deep {
  .hover {
    &:hover {
      background-color: #ccc;
    }
  }
}
</style>
