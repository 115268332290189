var render = function () {
  var _vm$airportData, _vm$airportData2, _vm$airportData3, _vm$airportData4, _vm$airportData5, _vm$airportData6, _vm$airportData7, _vm$airportData8, _vm$airportData9, _vm$airportData10, _vm$airportData11, _vm$airportData12, _vm$airportData13;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "SearchAirport",
    attrs: {
      "id": "search-airport"
    }
  }, [_c('div', {
    staticClass: "position-relative bg-white rounded px-50 py-25",
    staticStyle: {
      "height": "60px"
    }
  }, [_c('label', {
    staticClass: "d-block text-truncate w-100 h-100 mb-0",
    attrs: {
      "for": "search-airport-input-".concat(_vm.idSearch)
    },
    on: {
      "click": function click($event) {
        _vm.isShowAirportGroup = !_vm.isShowAirportGroup;
      }
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-start text-title"
  }, [_c('div', {
    staticClass: "text-dark mb-0",
    class: _vm.isMultiCity ? ' font-medium-3' : ' font-medium-4'
  }, [_vm._v(" " + _vm._s(((_vm$airportData = _vm.airportData) === null || _vm$airportData === void 0 ? void 0 : _vm$airportData.city) || ((_vm$airportData2 = _vm.airportData) === null || _vm$airportData2 === void 0 ? void 0 : _vm$airportData2.name)) + " ")]), _c('span', {
    staticClass: "text-primary ml-50"
  }, [_vm._v(_vm._s((_vm$airportData3 = _vm.airportData) === null || _vm$airportData3 === void 0 ? void 0 : _vm$airportData3.iata))])]), _c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "text-black-50 mb-0",
    staticStyle: {
      "font-weight": "400"
    }
  }, [_vm._v(" " + _vm._s((_vm$airportData4 = _vm.airportData) !== null && _vm$airportData4 !== void 0 && _vm$airportData4.city ? "".concat((_vm$airportData5 = _vm.airportData) === null || _vm$airportData5 === void 0 ? void 0 : _vm$airportData5.name, ",") : '') + " " + _vm._s(((_vm$airportData6 = _vm.airportData) === null || _vm$airportData6 === void 0 ? void 0 : _vm$airportData6.country) || ((_vm$airportData7 = _vm.airportData) === null || _vm$airportData7 === void 0 ? void 0 : _vm$airportData7.group)) + " ")])])])]), _c('b-form-input', {
    ref: _vm.SearchAirportInput,
    staticClass: "position-absolute position-top-0 position-left-0 d-none w-100 bg-white border-0 font-medium-3 fw-600",
    class: {
      'd-block': _vm.isShowAirportGroup
    },
    staticStyle: {
      "height": "60px",
      "color": "#0e6c8e"
    },
    attrs: {
      "id": "search-airport-input-".concat(_vm.idSearch),
      "formatter": function formatter(value) {
        return value.toUpperCase();
      },
      "autocomplete": "off",
      "placeholder": "".concat((_vm$airportData8 = _vm.airportData) !== null && _vm$airportData8 !== void 0 && _vm$airportData8.city || (_vm$airportData9 = _vm.airportData) !== null && _vm$airportData9 !== void 0 && _vm$airportData9.name ? "".concat(((_vm$airportData10 = _vm.airportData) === null || _vm$airportData10 === void 0 ? void 0 : _vm$airportData10.city) || ((_vm$airportData11 = _vm.airportData) === null || _vm$airportData11 === void 0 ? void 0 : _vm$airportData11.name), ",") : '', " ").concat((_vm$airportData12 = _vm.airportData) !== null && _vm$airportData12 !== void 0 && _vm$airportData12.iata ? "(".concat((_vm$airportData13 = _vm.airportData) === null || _vm$airportData13 === void 0 ? void 0 : _vm$airportData13.iata, ")") : '')
    },
    on: {
      "input": _vm.handleSearchAirport,
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.onEnterInput($event);
      }
    },
    model: {
      value: _vm.searchText,
      callback: function callback($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  })], 1), _vm.isShowAirportGroup ? _c('div', {
    staticClass: "position-absolute bg-white  rounded box-shadow-1",
    staticStyle: {
      "left": "0",
      "top": "105%",
      "z-index": "99",
      "width": "100%",
      "max-height": "380px",
      "padding": "4px 0"
    }
  }, [!_vm.searchText ? _c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "max-height": "372px"
    }
  }, [_c('div', _vm._l(_vm.getAirportGroup.groups, function (airportGroup, index) {
    return _c('div', {
      key: index,
      staticClass: "py-50 cursor-pointer px-1 hover",
      class: {
        'bg-primary text-white': index === _vm.groupSelectedIndex
      },
      on: {
        "click": function click($event) {
          return _vm.handleChooseGroup(index);
        }
      }
    }, [_c('span', {
      staticClass: "text-uppercase"
    }, [_vm._v(_vm._s(airportGroup.displayName))])]);
  }), 0), _c('div', {
    staticClass: "overflow-auto flex-1"
  }, _vm._l(_vm.airportOfSelectedGroup, function (airport, index) {
    var _vm$airportData14;
    return _c('div', {
      key: index,
      staticClass: "py-25 px-1 cursor-pointer hover overflow-auto",
      class: {
        'bg-light-primary': airport.iata === ((_vm$airportData14 = _vm.airportData) === null || _vm$airportData14 === void 0 ? void 0 : _vm$airportData14.iata)
      },
      on: {
        "click": function click($event) {
          return _vm.onSelectAirport(airport);
        }
      }
    }, [_vm._v(" " + _vm._s(airport.name) + " (" + _vm._s(airport.iata) + ") ")]);
  }), 0)]) : _c('div', {
    staticClass: "overflow-auto p-0",
    staticStyle: {
      "max-height": "380px"
    }
  }, [_vm.isLoadingSearchAirport ? _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary",
      "label": "Text Centered",
      "small": "",
      "tag": "span"
    }
  }), _c('span', {
    staticClass: "pl-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.loadingAirport')) + " ")])], 1) : !_vm.airportOptions.length ? _c('div', {
    staticClass: "d-flex-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.noOptions')) + " ")]) : _c('div', _vm._l(_vm.airportOptions, function (airport, index) {
    var _vm$airportData15;
    return _c('div', {
      key: index,
      staticClass: "py-25 px-1 cursor-pointer hover",
      class: {
        'bg-light-primary text-white': airport.iata === ((_vm$airportData15 = _vm.airportData) === null || _vm$airportData15 === void 0 ? void 0 : _vm$airportData15.iata)
      },
      on: {
        "click": function click($event) {
          return _vm.onSelectAirport(airport);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-start text-truncate"
    }, [_c('strong', [_vm._v(_vm._s(airport === null || airport === void 0 ? void 0 : airport.city))]), _c('strong', {
      class: "text-primary ml-1 text-truncate font-weight-bold"
    }, [_vm._v(" " + _vm._s(airport === null || airport === void 0 ? void 0 : airport.iata) + " ")])]), _c('div', {
      staticClass: "d-flex justify-content-between text-truncate"
    }, [_c('span', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(airport === null || airport === void 0 ? void 0 : airport.name) + ", " + _vm._s(airport === null || airport === void 0 ? void 0 : airport.country) + " ")])])]);
  }), 0)])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }